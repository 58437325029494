<template>
    <div class="banner_box" :class="adCategory != 1 ? 'hidden' : ''">
        <el-carousel :interval="5000" indicator-position="none">
            <el-carousel-item v-for="(item, index) in bannerList" :key="index">
                <div class="bg">
                    <img class="pointer banner_img" :src="item.imgUrl" alt="" @click="bannerBtn(item.linkUrl)" />

                </div>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
export default {
    props: {
        adCategory: {},
    },
    data() {
        return {
            bannerList: [],
        }
    },
    // 监听路由变化
    watch: {
        $route: 'urlName',
    },
    created() {
        this.getdata(this.adCategory)
    },
    methods: {
        // 监听url参数变化
        urlName() {
            this.getdata(this.adCategory)
        },
        getdata(adCategory) {
            this.api.findAdvertise({ adCategory: adCategory }).then((res) => {
                if (res.code == 0) {
                    this.bannerList = res.data
                }
            })
        },
        bannerBtn(url) {
            if (url != '') {
                window.open(url, '_blank')
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.hidden {
    ::v-deep.el-carousel {
        .el-carousel__container {
            .el-carousel__arrow {
                display: none !important;
            }
        }
    }
}

.banner_box {
    min-width: 1200px;
    height: 540px;

    ::v-deep.el-carousel__container {
        height: 540px;
    }

    ::v-deep.el-carousel {
        .el-carousel__container {
            .el-carousel__arrow {
                width: 60px;
                height: 60px;
                font-size: 24px;
                background: rgba(51, 51, 51, 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .banner_img {
        width: 100%;
        height: 540px;
    }
}
</style>