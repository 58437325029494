<template>
    <div class="newsCenterDetails_page">
        <Banner :adCategory="7"></Banner>
        <div class="content flex">
            <div class="details">
                <div class="title">
                    {{ newsData.newsTitle }}
                </div>
                <div class="time_num flex">
                    <div class="publishedTime">发表时间：{{ newsData.createTime }}</div>
                    <div class="browseNum ">浏览量：{{ newsData.browseNum }}</div>
                </div>
                <div class="richText" v-html="newsData.content" content>

                </div>
                <div class="upDown Between">
                    <!-- <div class="previous flex-center pointer">
                        <img class="mark" src="@/assets/img/newsCenter/mark.png" alt="">
                        <span class="cont row ">上一篇：焊工证怎么考</span>
                    </div>
                    <div class="nextArticle flex-center pointer">
                        <img class="mark" src="@/assets/img/newsCenter/mark.png" alt="">
                        <span class="cont row ">下一篇：建筑工程师怎么考办公楼改造...</span>
                    </div> -->
                </div>
            </div>
            <div class="recommended">
                <div class="title flex-center">
                    <img class="icon_line" src="@/assets/img/newsCenter/line.png" alt="">
                    推荐新闻
                </div>
                <div class="recommended_content">
                    <div class="list flex-center row pointer" @click="detailsBtn(item)"
                        v-for="(item, index) in recommendList" :key="index">
                        {{item.newsTitle}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Banner from '@/components/banner'
export default {
    components:{
        Banner
    },
    data(){
        return{
            newsData:{},
            recommendList:[],
            params:{
                id:""
            }
        }
    },
    mounted(){
        document.documentElement.scrollTop = 0;
        this.params.id = this.$route.query.id
        this.getData()
        this.getRecommend()
    },
    methods:{
      getData(){
            this.api.findNewsInfo(this.params).then(res=>{
                res.data.createTime = res.data.createTime.slice(0,10)
                this.newsData = res.data
            })
      },
        getRecommend() {
            this.api.findRecommendNews ().then(res => {
                this.recommendList = res.data
            })
        },
        detailsBtn(item){
            this.params.id = item.id
            this.getData()
        }
    }
}
</script>
<style lang="scss" scoped>



    .newsCenterDetails_page{
        .details{
            width: 770px;
            .title{
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
                line-height: 24px;
                margin-top: 89px;
            }
            .time_num{
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #888888;
                line-height: 14px;
                margin-top: 30px;
                .publishedTime{}
                
                .browseNum {
                    margin-left: 57px;
                }
            }
            .richText{
                margin-top: 30px;
                padding:0px 20px;
                box-sizing: border-box;
                
            }
            .upDown{
                margin-top: 67px;
                margin-bottom: 99px;
                div{
                   .mark{
                       width: 6px;
                       height: 6px;
                   }
                
                    .cont {
                        width: 283px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        margin-left: 8px;
                    }
                }
            }
        }
         
        .recommended {
            margin-top: 80px;
            margin-left: 72px;
            background: #f5f5f5;
            width: 325px;
            height: 295px;
            border: 1px solid rgba(239, 239, 239, 0.96);
            padding: 27px 0px 0px 18px;
            .title{
               
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #333333;
                .icon_line{
                    width: 4px;
                    height: 30px;
                    margin-right: 10px;
                }
            }
            .recommended_content::-webkit-scrollbar-thumb {
                background-color: #003C7E;
                border-left: 2px solid transparent;
                border-top: 2px solid transparent;

            }

            .recommended_content::-webkit-scrollbar {
                width: 5px;
                height: 5px;
                background: #f2f2f2;
            }
            .recommended_content{
                margin-top: 12px;
                height: 220px;
                overflow: hidden;
                overflow-y: scroll;
                padding-right: 15px;
                // .list:last-child{
                //     border-bottom: none !important;
                // }
                .list{
                    width: 292px;
                    height: 53px;
                    border-bottom: 1px solid #B7B7B7;
                }

            }
        }
    }
</style>